import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { WebapiService } from '../webapi.service';
import { NavController } from '@ionic/angular';
import * as moment from 'moment';

@Component({
  selector: 'app-budgetconfig',
  templateUrl: './budgetconfig.component.html',
  styleUrls: ['./budgetconfig.component.scss'],
})
export class BudgetconfigComponent implements OnInit {

  @Output() updatedconfig = new EventEmitter<any>();
  @Output() addnew = new EventEmitter<any>();

  @Input() configind_new = 0;
  @Input() config:any;
  @Input() values:any;

  f_flag=false;
  public daterange: any = {};

  partnerfilter=[];
  partnerfilterstr="";
  yearlist=["FY23","FY24","FY25","FY26","FY27","FY28","FY29","FY30","FY31","FY32","FY33"]
  public options: any = {
    locale: { format: 'MM-DD-YYYY' },
    alwaysShowCalendars: false,
    singleDatePicker: true,
    startDate:(new Date().getMonth()+1)+"/"+new Date().getDate()+"/"+new Date().getFullYear(),

   //  maxDate:'07/25/2023',
 //    "maxSpan": {
 //     "days": 6
 //  },
  "autoApply": true,


  };
  f_message="Please wait.."
  filelist=[]
  stypec=""
  constructor(private router:Router,public service: WebapiService,public navController:NavController) { 

    this.getall();
    this.getallc();
    // this.budgeting_config();

    // console.log(startdate)
    // console.log(date)

   

  }

  D_flag=false;
  D_i=-1
  sall="";

setyear(){

  this.values.Curr_FY=this.yearlist[this.yearlist.indexOf(this.values.Past_FY)+1]
  this.values.Next_FY=this.yearlist[this.yearlist.indexOf(this.values.Curr_FY)+1]
  if( this.values.Past_FY.replace("FY","20")!=this.values.Quater_Scope_start[0].split("-")[2])
   this.getDates( this.values.Past_FY,this.values.Curr_FY,this.values.Next_FY);

}
getDates(fy1,fy2,fy3){

  var year = parseInt(fy1.replace("FY","20"))
  var month = 6
  var startDate = moment([year, month])

  var qstart=[]
  var qend=[]
  var date,startdate,finaldate;
  if(startDate.endOf('month').day()==6)
  {
    finaldate=startDate.endOf('month').format('MM-DD-YYYY');
     
  }
  else if(startDate.endOf('month').day()<6)
  {
    finaldate=startDate.endOf('month').subtract(1, 'weeks').endOf('week').format('MM-DD-YYYY');
  }

  if(startDate.endOf('month').day()==0)
  {
    startdate=startDate.endOf('month').format('MM-DD-YYYY');
     
  }
  else if(startDate.endOf('month').day()>0)
  {
    startdate=startDate.endOf('month').startOf('week').format('MM-DD-YYYY');
  }
  qstart.push(startdate)

  // var year = parseInt(fy1.replace("FY","20"))
  month = 9
  startDate = moment([year, month])

  // var qstart=[]
  // var date,startdate;
  if(startDate.endOf('month').day()==6)
  {
    date=startDate.endOf('month').format('MM-DD-YYYY');
     
  }
  else if(startDate.endOf('month').day()<6)
  {
    date=startDate.endOf('month').subtract(1, 'weeks').endOf('week').format('MM-DD-YYYY');
  }

  if(startDate.endOf('month').day()==0)
  {
    startdate=startDate.endOf('month').format('MM-DD-YYYY');
     
  }
  else if(startDate.endOf('month').day()>0)
  {
    startdate=startDate.endOf('month').startOf('week').format('MM-DD-YYYY');
  }
  qstart.push(startdate)
  qend.push(date)
  // this.values.Quater_Scope_start()

    year = parseInt(fy2.replace("FY","20"))
    month = 0
    startDate = moment([year, month])
  
    // var qstart=[]
    // var date,startdate;
    if(startDate.endOf('month').day()==6)
    {
      date=startDate.endOf('month').format('MM-DD-YYYY');
       
    }
    else if(startDate.endOf('month').day()<6)
    {
      date=startDate.endOf('month').subtract(1, 'weeks').endOf('week').format('MM-DD-YYYY');
    }
  
    if(startDate.endOf('month').day()==0)
    {
      startdate=startDate.endOf('month').format('MM-DD-YYYY');
       
    }
    else if(startDate.endOf('month').day()>0)
    {
      startdate=startDate.endOf('month').startOf('week').format('MM-DD-YYYY');
    }
    qstart.push(startdate)
    qend.push(date)


    year = parseInt(fy2.replace("FY","20"))
    month = 3
    startDate = moment([year, month])
  
    // var qstart=[]
    // var date,startdate;
    if(startDate.endOf('month').day()==6)
    {
      date=startDate.endOf('month').format('MM-DD-YYYY');
       
    }
    else if(startDate.endOf('month').day()<6)
    {
      date=startDate.endOf('month').subtract(1, 'weeks').endOf('week').format('MM-DD-YYYY');
    }
  
    if(startDate.endOf('month').day()==0)
    {
      startdate=startDate.endOf('month').format('MM-DD-YYYY');
       
    }
    else if(startDate.endOf('month').day()>0)
    {
      startdate=startDate.endOf('month').startOf('week').format('MM-DD-YYYY');
    }
    qstart.push(startdate)
    qend.push(date)


    year = parseInt(fy2.replace("FY","20"))
    month = 6
    startDate = moment([year, month])
  
    // var qstart=[]
    // var date,startdate;
    if(startDate.endOf('month').day()==6)
    {
      date=startDate.endOf('month').format('MM-DD-YYYY');
       
    }
    else if(startDate.endOf('month').day()<6)
    {
      date=startDate.endOf('month').subtract(1, 'weeks').endOf('week').format('MM-DD-YYYY');
    }
  
    if(startDate.endOf('month').day()==0)
    {
      startdate=startDate.endOf('month').format('MM-DD-YYYY');
       
    }
    else if(startDate.endOf('month').day()>0)
    {
      startdate=startDate.endOf('month').startOf('week').format('MM-DD-YYYY');
    }
    qstart.push(startdate)
    qend.push(date)


    year = parseInt(fy2.replace("FY","20"))
    month = 9
    startDate = moment([year, month])
  
    // var qstart=[]
    // var date,startdate;
    if(startDate.endOf('month').day()==6)
    {
      date=startDate.endOf('month').format('MM-DD-YYYY');
       
    }
    else if(startDate.endOf('month').day()<6)
    {
      date=startDate.endOf('month').subtract(1, 'weeks').endOf('week').format('MM-DD-YYYY');
    }
  
    if(startDate.endOf('month').day()==0)
    {
      startdate=startDate.endOf('month').format('MM-DD-YYYY');
       
    }
    else if(startDate.endOf('month').day()>0)
    {
      startdate=startDate.endOf('month').startOf('week').format('MM-DD-YYYY');
    }
    qstart.push(startdate)
    qend.push(date)


    year = parseInt(fy3.replace("FY","20"))
    month = 0
    startDate = moment([year, month])
  
    // var qstart=[]
    // var date,startdate;
    if(startDate.endOf('month').day()==6)
    {
      date=startDate.endOf('month').format('MM-DD-YYYY');
       
    }
    else if(startDate.endOf('month').day()<6)
    {
      date=startDate.endOf('month').subtract(1, 'weeks').endOf('week').format('MM-DD-YYYY');
    }
  
    if(startDate.endOf('month').day()==0)
    {
      startdate=startDate.endOf('month').format('MM-DD-YYYY');
       
    }
    else if(startDate.endOf('month').day()>0)
    {
      startdate=startDate.endOf('month').startOf('week').format('MM-DD-YYYY');
    }
    qstart.push(startdate)
    qend.push(date)

    year = parseInt(fy3.replace("FY","20"))
    month = 3
    startDate = moment([year, month])
  
    // var qstart=[]
    // var date,startdate;
    if(startDate.endOf('month').day()==6)
    {
      date=startDate.endOf('month').format('MM-DD-YYYY');
       
    }
    else if(startDate.endOf('month').day()<6)
    {
      date=startDate.endOf('month').subtract(1, 'weeks').endOf('week').format('MM-DD-YYYY');
    }
  
    if(startDate.endOf('month').day()==0)
    {
      startdate=startDate.endOf('month').format('MM-DD-YYYY');
       
    }
    else if(startDate.endOf('month').day()>0)
    {
      startdate=startDate.endOf('month').startOf('week').format('MM-DD-YYYY');
    }
    qstart.push(startdate)
    qend.push(date)
    // qend.push(finaldate)

    
    year = parseInt(fy3.replace("FY","20"))
    month = 6
    startDate = moment([year, month])
  
    // var qstart=[]
    // var date,startdate;
    if(startDate.endOf('month').day()==6)
    {
      date=startDate.endOf('month').format('MM-DD-YYYY');
       
    }
    else if(startDate.endOf('month').day()<6)
    {
      date=startDate.endOf('month').subtract(1, 'weeks').endOf('week').format('MM-DD-YYYY');
    }
  
    if(startDate.endOf('month').day()==0)
    {
      startdate=startDate.endOf('month').format('MM-DD-YYYY');
       
    }
    else if(startDate.endOf('month').day()>0)
    {
      startdate=startDate.endOf('month').startOf('week').format('MM-DD-YYYY');
    }
    // qstart.push(startdate)
    qend.push(date)

    
    // this.values.Quater_Scope_start()
    this.values.Quater_Scope_start=qstart;  
    this.values.Quater_Scope_end=qend;  

    console.log(qstart);
    console.log(qend);




}
splist(){
  this.partnerfilter=[];
  this.f_filelist.forEach(element => {
    this.partnerfilter.push(element.type);
      
  });
  //console.log(this.partnerfilter);

}
uplist(){
  this.partnerfilter=[];
}
plist(k){
  if(this.partnerfilter.indexOf(k)<0)
     this.partnerfilter.push(k)
  else
   {
     var j=this.partnerfilter.indexOf(k);
     this.partnerfilter.splice(j,1);
   }   
  //console.log(this.partnerfilter);
}
download(type,i) {

  this.D_i=i
//   this.service.postData({}, 'download/'+type).then((result) => {
//     var temp: any = result;
//     this.resultr = temp
//     //console.log(this.resultr);

// })

this.D_flag=true;
this.service.download(type).then((result) => {
  this.D_flag=false;
  this.D_i=-1

  

});
}

  selectedDate(value: any, datepicker?: any) {
    // this is the date  selected
    //console.log(value);
    this.date=value.start.format('MM/DD/YYYY');
    //console.log(this.date);

    // any object can be passed to the selected event and it will be passed back here
    // datepicker.start = value.start.startOf('week');
    // datepicker.end = value.end.endOf('week');
  }
  stype=''
  partnerlist=[
    'DBS',
    'EI',
    'DHL',
    'FLXT',
    'KWE',
    'EMO',
    'CEVA'
]
date=""

filelistc=[];
  ngOnInit() {

    console.log(this.values.Quater_Scope_start[0])
  }
  
  unique_date=[];
  selectedadate="";
  getselectedmonth(k){
    this.f_filelist=this.filelist.filter(function(item:any){return item.file_month===k})
 

    //return padb;
  }
  f_filelist=[];
  getall()
  {
    this.f_flag=true;
    this.f_message="Please wait.."
    this.filelist=[]
    
         this.service.postData({type:'i'}, 'getallfiletype').then((result) => {
                  var temp: any = result;
                   //console.log(temp)
                  
                  this.filelist =temp.data
                     //unique partner
                this.unique_date = this.filelist
                      .map((item) => item['file_month'])
                      .filter(
                          (value, index, current_value) => current_value.indexOf(value) === index
                      );
                  //console.log("2");
                this.selectedadate=this.unique_date[0];
                this.getselectedmonth(this.selectedadate)
                this.f_flag=false;

                
            
            }).catch(e => {
              this.f_flag=true;
              this.f_message="Something goes wrong!"

            });
            
            
  }

  // volumeinputtype=_json['volumeinputtype']
  // # Model Power Lane Adjustment ,Parcel Adjustment

  // ModelPowerLaneAdjustment=_json['ModelPowerLaneAdjustment']
  // Parcel_Adjustment=_json['Parcel_Adjustment']


  // # Volume Forcast list
  // VolumeForcastlist=_json['VolumeForcastlist']

  // yoyq1=_json['yoyq1']
  // yoyq2=_json['yoyq2']
  // yoyq3=_json['yoyq3']
  // yoyq4=_json['yoyq4']
  public dataList:any; 
  // config:any;
  configlist:any=[]
  dataList_lane:any;
  // values = {
  //   Curr_FY:"FY24",
  //   Next_FY:"FY25",
  //   Past_FY:"FY23",

  //   volumeinputtype:'By-Lane',
  //   Parcel_Adjustment:0.00/100,
  //   Fuel_Surcharge:0.0927,
  //   ModelPowerLaneAdjustment:0.1662,
  //   VolumeForcastlist: [0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00],
  //   yoyq1:0.92,
  //   yoyq2:0.96,
  //   yoyq3:0.884,
  //   yoyq4:0.924,
  //   // Quater_Scope_start:["30-07-2023","29-10-2023","28-01-2024","28-04-2024","28-07-2024","01-11-2024","01-02-2025","01-05-2025"],
  //   // Quater_Scope_end:["28-10-2023","27-01-2024","27-04-2024","27-07-2024","31-10-2024","31-01-2025","30-04-2025","31-07-2025"],
  //   Quater_Scope_start:["07-30-2023","10-29-2023","01-28-2024","04-28-2024","07-28-2024","11-01-2024","02-01-2025","05-01-2025"],
  //   Quater_Scope_end:["10-28-2023","01-27-2024","04-27-2024","07-27-2024","10-31-2024","01-31-2025","04-30-2025","07-31-2025"],
  //   Rate_Forecast_Best:[0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00],
  //   Rate_Forecast_Target:[0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00],
  //   Rate_Forecast_Worst:[0.00,0.00,0.00,0.00,0.00,0.00,0.00,0.00],
  //   TypeIIAccessorials:4/100,
  //   Overcapacity_Charges:0.00/100,
  //   Fuel_Surcharge1:0,
  //   Fuel_Surcharge2:0,
  //   Fuel_Surcharge3:0,
  //   Fuel_Surcharge4:0,
  //   Fuel_Surcharge5:0,
  //   Fuel_Surcharge6:0,
  //   Fuel_Surcharge7:0,
  //   Fuel_Surcharge8:0,
  //   Fuel_Surcharge_Target1:0,
  //   Fuel_Surcharge_Target2:0,
  //   Fuel_Surcharge_Target3:0,
  //   Fuel_Surcharge_Target4:0,
  //   Fuel_Surcharge_Target5:0,
  //   Fuel_Surcharge_Target6:0,
  //   Fuel_Surcharge_Target7:0,
  //   Fuel_Surcharge_Target8:0,

  //   Fuel_Surcharge_Worst1:0,
  //   Fuel_Surcharge_Worst2:0,
  //   Fuel_Surcharge_Worst3:0,
  //   Fuel_Surcharge_Worst4:0,
  //   Fuel_Surcharge_Worst5:0,
  //   Fuel_Surcharge_Worst6:0,
  //   Fuel_Surcharge_Worst7:0,
  //   Fuel_Surcharge_Worst8:0,
  //   sp_list:{
  //     'Special_Programs' : ["","ACRP", "CE", "Direct_Ship","OOGW","Origin_By_Pass","Double_By_Pass","Dubai_X_Dock","Fedex_IPD","White_Glove","WWT","MX_Program","FCC_Non_FCC"],
  //     'Premium':["0.0","0.02","0.02","0.02","0.02","0.02","0.02","0.02","0.02","0.02","0.02","0.02","0.02"]
  //     },
  //     title:"default"


    
  // }

  status="";

  formatdateforconfiguration(){
    var i=0;

    this.values.Quater_Scope_start.forEach(element => {

      // this.values.Quater_Scope_start[i]=(new Date(element)).getMonth()+1+"-"+(new Date(element)).getDate()+"-"+(new Date(element)).getFullYear()

      var month=(new Date(element)).getMonth();
      var year=(new Date(element)).getFullYear();
      var date=(new Date(element)).getDate();

      //console.log(month)
      var m,y,d;
      if(month<9)
        m= "0"+(month+1);
      else
        m=month+1

      if(date<10)
        d= "0"+(date);
      else
        d=date

        y=year;

      this.values.Quater_Scope_start[i]=d+"-"+m+"-"+y
     
      i=i+1;
    });

    i=0;
    this.values.Quater_Scope_end.forEach(element => {

      var month=(new Date(element)).getMonth();
      var year=(new Date(element)).getFullYear();
      var date=(new Date(element)).getDate();

      var m,y,d;
      if(month<9)
        m= "0"+(month+1);
      else
        m=month+1

      if(date<10)
        d= "0"+(date+1);
      else
        d=date

        y=year;

        
      


      this.values.Quater_Scope_end[i]=d+"-"+m+"-"+y
     
      i=i+1;
    });

  }
  setMessage9() {
      

    //this.values.partnerlist=this.partnerlist;
    // this.values.partnerlist=[];
    // this.f_filelist.forEach(element => {
    //     this.values.partnerlist.push(element.type.split(" ")[0].trim())
    // });
    this.formatdateforconfiguration()   

    this.status="Please wait..";

    //console.log(this.values)
    this.service.postData(this.values, 'budgeting_model_process').then((result) => {
      var temp: any = result;
      this.resultr = temp
      //console.log(temp);
      //this.getall();
  
      // this.dataList=JSON.parse(temp.budgeting_model_output);
      // this.dataList_lane=JSON.parse(temp.budgeting_model_output_groupby);

      this.status="Completed"
      // //console.log(this.dataList)
      // //console.log(this.dataList_lane)

  
    }).catch(e => {

      this.status="Somthing goes wrong!"
      
    });
  
  }
  budgeting_config(){
    var obj = {
      email : localStorage.getItem('email'),      
    } 
    this.service.postData(obj, 'budgeting_config').then((result) => {
      var temp: any = result;
       //console.log(temp)
      
      this.configlist =temp.data
      if(this.configlist.length>0)
      {
        var i=0;
        this.configlist.forEach(element => {
          
          this.configlist[i].sConfig=JSON.parse(element.sConfig)
          i=i+1;

        });
        //alert(this.tid)
        // if(this.tid>-1)
        //  {
          // this.configind_new = this.tid;         
          // this.config=this.configlist[this.tid];
        //  }
        // else

           this.config=this.configlist[this.configlist.length-1];
           this.configind_new= this.configlist.length-1;   
           this.values=JSON.parse(JSON.stringify(this.config.sConfig))      
        // }
          //console.log(this.configlist);
      }
      else{

        this.configtitle="default"
        this.update_config('add');
      }
      // this.f_flag=false;

}).catch(e => {
  // this.f_flag=true;
  // this.f_message="Something goes wrong!"

});

  }
  onchangeconfig(){
    
    this.config=JSON.parse(JSON.stringify(this.configlist[this.configind_new]));
    this.values=JSON.parse(JSON.stringify(this.config.sConfig))
    //console.log(this.values)

  }
  configtitle=""
  tid=0;
  update_config(key)
  {
    this.f_flag=true;
    this.f_message="Please wait.."
    this.filelist=[]
    var cnf=JSON.parse(JSON.stringify(this.values));
    if(key=='add')
     cnf.title=this.configtitle;

     console.log(cnf)
    var obj = {
      email : localStorage.getItem('email'),
      iId:-1,
      type:key,
      config:JSON.stringify(cnf),
    } 
    if(key=='update')
    {
      obj.iId=this.config.iId
      
    }

         this.service.postData(obj, 'budgeting_config_update').then((result) => {
                  var temp: any = result;
//                  //console.log(temp)
                 this.tid=this.configind_new;
                 this.configtitle="";

                 
                 if(key=='update')
                  {

                    this.updatedconfig.emit(cnf);
                    // this.configlist[this.tid].sConfig=cnf;
                  }
                  else{
                     this.addnew.emit(true)
                  }
                  //  this.budgeting_config();
                   this.f_flag=false;
                
            }).catch(e => {
              this.f_flag=true;
              this.f_message="Something goes wrong!"

            });
            
            
  }
  delete_config()
  {
    this.f_flag=true;
    this.f_message="Please wait.."
    this.filelist=[]
    // var cnf=JSON.parse(JSON.stringify(this.values));
    // if(key=='add')
    //  cnf.title=this.configtitle;

    var obj = {
      email : localStorage.getItem('email'),
      iId:-1,
      // type:key,
      // config:JSON.stringify(cnf),
    } 
    // if(key=='update')
    // {
      obj.iId=this.configlist[this.configind_new].iId
      
    // }

         this.service.postData(obj, 'budgeting_config_delete').then((result) => {
                  var temp: any = result;
 
                   this.budgeting_config();
                   this.f_flag=false;

            }).catch(e => {
              this.f_flag=true;
              this.f_message="Something goes wrong!"

            });
            
            
  }
  getallc()
  {
    this.f_flag=true;
    this.f_message="Please wait.."
    this.filelist=[]
         this.service.postData({type:'gc'}, 'getallfiletype').then((result) => {
                  var temp: any = result;
                   //console.log(temp)
                  
                  this.filelistc =temp.data
                  //console.log("2");
                  this.f_flag=false;

                
            
            }).catch(e => {
              this.f_flag=true;
              this.f_message="Something goes wrong!"

            });
            
            
  }
  uploadstatus=false;
  resultr:any;
  uploadstatus_message=""
  upload(e,type,tp,date)
   {
     
       this.uploadstatus=true;
       this.uploadstatus_message="Please wait.."
 
       var form_data = new FormData();  
             ////console.log(model);
             //console.log(e.target.files)
             for(var i=0;i<e.target.files.length;i++)
                 form_data.append('file', e.target.files.item(i)); 
             
                 form_data.append('type', type); 
                 form_data.append('uby', localStorage.getItem("email"));
                 form_data.append('tp', tp); 
                 form_data.append('date', date); 
 

                 this.service.postData1(form_data, 'upload_type').then((result) => {
                   var temp: any = result;
                   this.resultr = temp
                   //console.log("2");
                   this.uploadstatus=false;
                   this.uploadstatus_message="File uploaded successfully"
 
                   this.getall();
                   this.getallc();
                   
             
             }).catch(e => {
               this.uploadstatus=true;
               this.uploadstatus_message="Somthing goes wrong!";
                  
             });
     
            
   }
   gototraining(){
    
    this.router.navigateByUrl('/demandsensing');


   }
  GOtoNExtPage_2() {
    this.router.navigateByUrl('/operational-concern');

  }
  GOtoNExtPage_1() {
    
    this.router.navigateByUrl('/dashboard');
  }
  GOtoNExtPage_P()
  {
    this.router.navigateByUrl('/operational-concern1');
  }
  GOtomessage() {
      
    this.router.navigateByUrl('/messages');
  }
  gotodashboard()
  {
    this.router.navigateByUrl('/budgeting/'+this.configind_new);
  
    
  }

  
  go_config()
  {
    this.router.navigateByUrl('/mle-training');
  
  }
}
