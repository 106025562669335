import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { WebapiService } from '../webapi.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-watchlist',
  templateUrl: './watchlist.component.html',
  styleUrls: ['./watchlist.component.scss'],
})
export class WatchlistComponent implements OnInit {
  page = 1
  pflag=false;
  alerttype={
    'type':'External_Energy',
    'userID':'125',
     list:[]
  }
  lflag=false;
flag={
  sall1:false,
sall2:false,

sall3:false,

sall4:false,
sall5:false,
}
  configlist=[]
  constructor(public service: WebapiService,public router: Router,public modalController: ModalController) { 

    this.alerttype.userID=this.service.userdata.iid;
    this.alerttype.userID=localStorage.getItem('user')
     this.getdate();

  }
 
  
  
  ngOnInit() { }

  next() {
    if (this.page < 5)
      this.page = this.page + 1;
    switch(this.page)
    {
      case 1: this.alerttype.type="External_Energy";
     
      break;
      case 2: this.alerttype.type="External_Alert";
      
      break;
      case 3: this.alerttype.type="portfolio_areas";
      
      break;
      case 4: this.alerttype.type="Lane";
      break;
      case 5: this.alerttype.type="Parner";
      break;
    }

  }

  AllSelect()
  {

//    console.log(k);
        
        console.log(this.page-1)
        console.log(this.configlist[this.page-1]);

        for(var i=0;i<this.configlist[this.page-1].data.length;i++)
        {
          switch(this.page)
          {
            case 1:console.log(this.flag.sall1);this.configlist[this.page-1].data[i].sub_flag=!this.flag.sall1;break;
            case 2:console.log(this.flag.sall2);this.configlist[this.page-1].data[i].sub_flag=!this.flag.sall2;break;

            case 3:console.log(this.flag.sall3);this.configlist[this.page-1].data[i].sub_flag=!this.flag.sall3;break;

            case 4:console.log(this.flag.sall4);this.configlist[this.page-1].data[i].sub_flag=!this.flag.sall4;break;

            case 5:console.log(this.flag.sall5);this.configlist[this.page-1].data[i].sub_flag=!this.flag.sall5;break;

          }
        }
  }
  submit() {

    this.adddate();
   

  }
  prev() {
    if (this.page > 1)
      this.page = this.page - 1;

      switch(this.page)
      {
        case 1: this.alerttype.type="External_Energy";
        break;
        case 2: this.alerttype.type="External_Alert";
        break;
        case 3: this.alerttype.type="portfolio_areas";
        break;
        case 4: this.alerttype.type="Lane";
        break;
        case 5: this.alerttype.type="Parner";
        break;
      }
  
  }

  onpageCLick(e) {
    this.page = e;
    switch(this.page)
    {
      case 1: this.alerttype.type="External_Energy";
      break;
      case 2:
      
      this.alerttype.type="External_Alert";
      //this.getdate();
      break;
      case 3: this.alerttype.type="portfolio_areas";
      break;
      case 4: this.alerttype.type="Lane";
      break;
      case 5: this.alerttype.type="Parner";
      break;
    }
  }
  getdate(){
    this.lflag=true;
    this.service.postData(this.alerttype, 'get_configuration').then((result) => {
      console.log(result, 'GetAllCorelation');
      var temp: any = result;
      this.configlist = [];
     
      this.configlist = temp.data;
      console.log(this.configlist, 'configlist');
      this.lflag=false;

    });

  }
  close()

  {
    this.modalController.dismiss({
      'dismissed': true
    });
  }
  adddate(){
    this.alerttype.list=this.configlist;
    this.pflag=true;
    this.service.postData(this.alerttype, 'add_configuration').then((result) => {
      console.log(result, 'addconfig');
      var temp: any = result;
      //this.configlist = [];
      console.log(temp)
       this.modalController.dismiss({
         'dismissed': true
       });
      this.pflag=false;
      //this.getdate();
      this.router.navigateByUrl('/dashboard');  

      //this.configlist = temp.data;
     //console.log(this.configlist, 'configlist');
    });

  }
}
