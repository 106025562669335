import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../webapi.service';

@Component({
  selector: 'app-partnerdetails',
  templateUrl: './partnerdetails.component.html',
  styleUrls: ['./partnerdetails.component.scss'],
})
export class PartnerdetailsComponent implements OnInit {

  weekdata;
  origin;
  destination;
  partnerlist:any=[];
  lc:any;
  opt={minimumFractionDigits:0, maximumFractionDigits: 0}
  opt1={minimumFractionDigits:2, maximumFractionDigits: 2}
  constructor(public service:WebapiService) { }
  loader=false;

  ngOnInit() {

    console.log(this.weekdata,this.origin,this.destination);

    var partner=localStorage.getItem('usertype');
    var obj ={
      oring:this.origin,
      destination:this.destination,
      week:this.weekdata,
      partner:partner
    }
   
    this.loader=true;

    this.service.postData(obj, 'get_weekly_prediction_bypartner_partner').then((result) => {
      
      var res:any=result;
      this.partnerlist=JSON.parse(res.df_filter_json);
      this.loader=false;
      console.log(this.partnerlist)
    });
  }

}
