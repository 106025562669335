import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modelview-topcorelation',
  templateUrl: './modelview-topcorelation.component.html',
  styleUrls: ['./modelview-topcorelation.component.scss'],
})
export class ModelviewTopcorelationComponent implements OnInit {

  modelData: any = [];
  TempmodelData: any = [];
  PartnerName: string;
  viewRecords: any = [];
  saveData: any[];
  constructor() { }
  lane="";
  indexId: any = 5;
  vall: false
  searchFactore:any={};
  ngOnInit() {
    console.log(this.modelData, " console.log(abc)");

    console.log(this.lane, " console.log(abc)");

    `${this.modelData.forEach(_ => {
      this.TempmodelData.push({ data: _ });
      console.log(_, " console.log(abc)")
    })}`;


    this.TempmodelData.forEach((_, index) => {
      if (index < this.indexId) {
        this.viewRecords.push({ data: _.data });
      }

    });
    // let keysOfObj = Object.keys(this.TempmodelData[0].data);

    this.PartnerName = localStorage.getItem('partner');
    console.log(this.viewRecords, "viewRecords");
    debugger
  }
  viewAll() {
    this.viewRecords = [];
    this.viewRecords = this.TempmodelData;

  }

  saveAsCSV(e) {
    console.log(this.viewRecords, 'viewRecords')
    let sampleJson: any = [];
    this.viewRecords.forEach(element => {
      sampleJson.push(element.data);

    });

    this.saveData = [];
    let a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    let csvData = this.ConvertToCSV(sampleJson);
    let blob = new Blob([csvData], { type: 'text/csv' });
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'Accessorial.csv';
    a.click();
  }

  ConvertToCSV(objArray) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = "";
    for (let index in objArray[0]) {
      //Now convert each value to string and comma-separated
      row += index + ',';
    }
    row = row.slice(0, -1);
    //append Label row with line break
    str += row + '\r\n';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line != '') line += ',';
        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }
}
