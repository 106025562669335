import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-capacitypredection',
  templateUrl: './capacitypredection.component.html',
  styleUrls: ['./capacitypredection.component.scss'],
})
export class CapacitypredectionComponent implements OnInit {

  @Input()  weekdata :any =[];

  

  LineChartdata_13week:any;
  constructor() { }

  ngOnInit() {
    console.log("hello")

    console.log(this.weekdata)
    this.drawgraphs();
  }
    
  drawgraphs (){


    //13 week graph

    var LineChartdata = {
      chartType: 'LineChart',
      dataTable: temp,
       options: {
        options: {
  
          title: "Last 13 week's actual demand and Weekly Trends",
          
        },
        hAxis: {
          title: 'Week'
       },
       vAxis:{
          title: 'Weight'
       },

        // hAxis: {  gridlines: {
        //   color: 'transparent'
        //  }},
        //  vAxis :{
        //   gridlines: {
        //     color: 'transparent'
        //    }
        //  },
         
        pointSize:3,
        // legend: { position: 'top', alignment: 'center' },
        legend: { position: 'top', alignment: 'center' ,textStyle: {fontSize: 9}},

        // seriesType: 'bars',
        // series: {3: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"#5DADE2"},4: {type: 'line',targetAxisIndex:0,lineDashStyle: [10, 2],color:"#95A5A6"},5: {type: 'line',targetAxisIndex:0,color:'black'},6: {type: 'line',targetAxisIndex:0,color:'blue'}},        
        
        colors: ['#AED6F1','#FAD7A0','#7FB3D5','#2C3E50'],
        'chartArea': {'width': '80%', 'height': '70%'},

        
      height:320,
      }
  
    }

    var temp=[];
    temp.push(['Week','Prediction lower','Prediction Upper'])

    this.weekdata.forEach(element => {

      // var week= (new Date(element.Week).getMonth()+1)+'/'+(new Date(element.Week).getDate()+1)+'/'+new Date(element.Week).getFullYear();
      // var utcdate=new Date(element.Week).toLocaleString('en-US', { timeZone: 'UTC' });
     var week=element['Prediction for Week bk']

      var mavg2=0
      var mavg4=0;
      var mavg3=0;
       var ocean_weekly=null
      if(element['Moving Avg 2wks']!=null)
        mavg2=element['Moving Avg 2wks']
      if(element['Moving Avg 4wks']!=null)
        mavg4=element['Moving Avg 4wks']
      if(element['Moving Avg 3mnths']!=null)
        mavg3=element['Moving Avg 3mnths']

      var lower=null;
      if(element['Predictions Lower']!=0)
         lower=element['Predictions Lower']
      var upper=null;
      
      if(element['Predictions Upper']!=0)
         upper=element['Predictions Upper']
         
      var actual=null;

      if(element['Actual COB Weight']!=0)
         actual=element['Actual COB Weight']

     var ocean=NaN;

         if(element['OCEAN_WEIGHT_CHARGEABLE']!=0)
         ocean=element['OCEAN_WEIGHT_CHARGEABLE']

      
        
      temp.push([week,lower,upper])
       
    });

    console.log(temp);
    LineChartdata.dataTable=temp;
    this.LineChartdata_13week=LineChartdata;
  }
}
