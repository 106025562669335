import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WebapiService } from '../webapi.service';

@Component({
  selector: 'app-partner-allocation',
  templateUrl: './partner-allocation.component.html',
  styleUrls: ['./partner-allocation.component.scss'],
})
export class PartnerAllocationComponent implements OnInit {
  uploadstatus:any
  uploadstatus_message:any
  resultr:any
  startdate:any
  expirydate:any
  bisactive:any=1
  constructor(public router:Router,public service:WebapiService) {

    this.getall();
   }

  ngOnInit() {}
  GOtoNExtPage_2() {
    
    this.router.navigateByUrl('/operational-concern');
  }
  GOtoNExtPage_1() {
    
    this.router.navigateByUrl('/dashboard');
  }
  GOtoNExtPage_P()
  {
    this.router.navigateByUrl('/operational-concern1');
  }
  GOtomessage() {
      
    this.router.navigateByUrl('/messages');
  }
  
  go_config()
  {
    this.router.navigateByUrl('/mle-training');
  
  }

  D_flag=false;
  D_i=-1
  dl_i=-1;
  nextstartdate:any;
  download(type,i) {

    this.D_i=i
  //   this.service.postData({}, 'download/'+type).then((result) => {
  //     var temp: any = result;
  //     this.resultr = temp
  //     console.log(this.resultr);
  
  // })
  var filename= new Date(this.resultlist[i].dtstartdate).getFullYear()+"_"+(new Date(this.resultlist[i].dtstartdate).getMonth()+1)+"_"+new Date(this.resultlist[i].dtstartdate).getDate();

   filename=filename+'_to_'+ new Date(this.resultlist[i].dtexpirydate).getFullYear()+"_"+(new Date(this.resultlist[i].dtexpirydate).getMonth()+1)+"_"+new Date(this.resultlist[i].dtexpirydate).getDate();
   filename=filename+'_'+'partner_config_file.csv'
  this.D_flag=true;
  this.service.download_allo(type,filename).then((result) => {
    this.D_flag=false;
    this.D_i=-1
  
  });
  }

  gotobench()
  {
    this.router.navigateByUrl('/benchmarking');
  }
  id=-1;

  stype=''
  
  upload(e)
  {
    
      this.uploadstatus=true;
      this.uploadstatus_message="Please wait.."

      var form_data = new FormData();  
            //console.log(model);
        //     startdate= request.form.get('startdate')
        // expirydate= request.form.get('expirydate')
        // filetype= request.form.get('filetype')
        // _by= request.form.get('uby')
        // bisactive= request.form.get('bisactive')

            console.log(e.target.files)
            for(var i=0;i<e.target.files.length;i++)
                form_data.append('file', e.target.files.item(i)); 
            
                form_data.append('filetype', this.stype); 
                form_data.append('startdate', new Date(this.startdate).getFullYear()+"/"+(new Date(this.startdate).getMonth()+1)+"/"+(new Date(this.startdate).getDate())); 
                if(this.stype=="1")
                  form_data.append('expirydate', (new Date(this.startdate).getFullYear()+3)+"/"+(new Date(this.startdate).getMonth()+1)+"/"+(new Date(this.startdate).getDate())); 
                else
                  form_data.append('expirydate', (new Date(this.expirydate).getFullYear())+"/"+(new Date(this.expirydate).getMonth()+1)+"/"+(new Date(this.expirydate).getDate())); 


                form_data.append('id', this.id+""); 

                form_data.append('bisactive', this.bisactive); 

                form_data.append('uby', localStorage.getItem("email")); 

                this.service.postData1(form_data, 'upload_partner_allocation').then((result) => {
                  var temp: any = result;
                  this.resultr = temp
                  console.log("2");
                  this.uploadstatus=false;
                  this.uploadstatus_message="File uploaded successfully"

                  this.getall();
                  
            
            }).catch(e => {
              this.uploadstatus=true;
              this.uploadstatus_message="Somthing goes wrong!";
                 
            });
    
           
  }

  Dl_flag=false;
  delete(f,i){
    this.Dl_flag=true;

    this.dl_i=i;
    this.service.postData({id:f.iId}, 'delete_partner_allocation').then((result) => {
          
      var temp: any = result;
      this.Dl_flag=false;
      this.dl_i=-1
      console.log(temp)
      this.getall()

    });
  }
  resultlist:any=[]
  loading=false;
  getall()
  {
    this.loading=true;
    this.resultlist=[]
    this.service.postData({type:''}, 'getallpartnerallocation').then((result) => {
          
      var temp: any = result;
               
      this.loading=false;


              this.resultr = temp
              this.resultlist =this.resultr.data;
              var list = this.resultlist.filter(function(item:any){return item.sfiletype==1} ).reverse() 

              // this.startdate=list[0];
              this.id=list[0].iId;
              console.log( this.startdate)

    });
  }

  sortByProperty(property){  
    return function(b,a){  
       if(a[property] > b[property])  
          return 1;  
       else if(a[property] < b[property])  
          return -1;  
   
       return 0;  
    }  
 }
}
