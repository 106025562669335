import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-model-view-line',
  templateUrl: './model-view-line.component.html',
  styleUrls: ['./model-view-line.component.scss'],
})
export class ModelViewLineComponent implements OnInit {
  modelData: any = [];
  TempmodelData: any = [];
  PartnerName: string;
  vall = false
  viewRecords: any = [];
  searchFactore: any;
  SearchData: any = {}
  Headers: any = {};
  Headervalue: any = {}
  saveData: any[];
  constructor() { }
  indexId: any = 3;
  ngOnInit() {

    `${this.modelData.forEach(_ => {
      this.TempmodelData.push({ data: _ });

    })}`;
    var abc: any;
    `${abc = this.searchFactore}`;
    var a: any;
    `${this.Headers}`;


    this.SearchData = abc;
    this.TempmodelData.forEach((_, index) => {
      if (index < this.indexId) {
        this.viewRecords.push({ data: _.data });
      }

    });
    let keysOfObj = Object.keys(this.TempmodelData[0].data);
    console.log(keysOfObj, "keysOfObj")

    this.PartnerName = localStorage.getItem('partner');
    console.log(this.modelData, "TempmodelData");
    debugger
  }

  vcall() {
    this.Headervalue = this.Headers
  }
  VeiwAll() {
    this.viewRecords = [];
    this.viewRecords = this.TempmodelData;

  }


  saveAsCSV() {
    console.log(this.viewRecords, 'viewRecords')
    let sampleJson: any = [];
    this.viewRecords.forEach(element => {
      console.log(element.data)
    var t=  {Year: element.data.Year,
Month: element.data.Month,
Partner: element.data.Partner,
Origin: element.data.Origin,
Destination: element.data.Destination,
Service_Level: element.data.Service_Level,
ATA: element.data.ATA,
Peak_Season_Kg: element.data.Peak_Season_Kg,
Fuel_Kg: element.data.Fuel_Kg,
Security_Surcharge_Kg: element.data.Security_Surcharge_Kg,
Transportation_ATA_0_45_Kg: element.data.Transportation_ATA_0_45_Kg,
Transportation_ATA_46_100_Kg: element.data.Transportation_ATA_46_100_Kg,
Transportation_ATA_101_300_Kg: element.data.Transportation_ATA_101_300_Kg,
Transportation_ATA_301_500_Kg: element.data.Transportation_ATA_301_500_Kg,
Transportation_ATA_501_1000_Kg: element.data.Transportation_ATA_501_1000_Kg,
Transportation_ATA__1000_Kg: element.data.Transportation_ATA__1000_Kg,
Transportation_ATA_Minimum_Cost: element.data.Transportation_ATA_Minimum_Cost,
Total_assesorial: element.data.Total_assesorial
    }
      sampleJson.push(t);

    });

    this.saveData = [];
    let a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    let csvData = this.ConvertToCSV(sampleJson);
    let blob = new Blob([csvData], { type: 'text/csv' });
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'Accessorial.csv';
    a.click();
  }

  ConvertToCSV(objArray) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = "";
    for (let index in objArray[0]) {
      //Now convert each value to string and comma-separated
      row += index + ',';
    }
    row = row.slice(0, -1);
    //append Label row with line break
    str += row + '\r\n';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line != '') line += ',';
        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }
}
