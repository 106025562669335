import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../webapi.service';

@Component({
  selector: 'app-ocean-shipper-data',
  templateUrl: './ocean-shipper-data.component.html',
  styleUrls: ['./ocean-shipper-data.component.scss'],
})
export class OceanShipperDataComponent implements OnInit {

  title="";
  shipper:any=[];
  lc:any;
  opt={minimumFractionDigits:0, maximumFractionDigits: 0}
  opt1={minimumFractionDigits:2, maximumFractionDigits: 2}
  constructor(public service:WebapiService) {

    console.log(this.service.shipperdata);

    this.shipper=this.service.shipperdata;
    var w=(new Date(this.shipper[0].Week).getMonth()+1)+'/'+(new Date(this.shipper[0].Week).getDate()+1)+'/'+new Date(this.shipper[0].Week).getFullYear();

    this.title= "Shipper weekly data for Lane "+this.shipper[0]['Lane Group']+" for week "+w;

   }

  ngOnInit() {}

}
