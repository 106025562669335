import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: any,lane): any {
    if (value) {
      return value.filter((listing: any) => listing.lane === lane);
  }  }

}
