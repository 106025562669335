import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-demandsensingconfig',
  templateUrl: './demandsensingconfig.component.html',
  styleUrls: ['./demandsensingconfig.component.scss'],
})
export class DemandsensingconfigComponent implements OnInit {
  threshold_config={
    warning_threshold_perc:70,
    overallocation_threshold_perc:90,
    volume:1000,
  }
  constructor(public modalctrl : ModalController) {

    var threshold_config = localStorage.getItem("thresholdconfig");
    if(threshold_config)
      this.threshold_config=JSON.parse(threshold_config);

   }
  resetwarning(){

    this.threshold_config.warning_threshold_perc=70
    localStorage.setItem("thresholdconfig",JSON.stringify(this.threshold_config));

   }
   close(){
    this.modalctrl.dismiss();

   }
   resetoverallocation(){
    this.threshold_config.overallocation_threshold_perc=90;
    localStorage.setItem("thresholdconfig",JSON.stringify(this.threshold_config));
   }
   saveconfig(){
      // this.warning_threshold_perc=this.threshold_config.warning_threshold_perc;
      // this.overallocation_threshold_perc=this.threshold_config.overallocation_threshold_perc;
      localStorage.setItem("thresholdconfig",JSON.stringify(this.threshold_config));
      this.modalctrl.dismiss();

   }
  ngOnInit() {}

}
