import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WebapiService } from '../webapi.service';
import { DaterangepickerComponent } from 'ng2-daterangepicker';

@Component({
  selector: 'app-dailtytraining',
  templateUrl: './dailtytraining.component.html',
  styleUrls: ['./dailtytraining.component.scss'],
})
export class DailtytrainingComponent implements OnInit {
  private picker: DaterangepickerComponent;

  f_flag=true;
  public daterange: any = {};

  partnerfilter=[];
  partnerfilterstr="";
  

  public options: any = {
    locale: { format: 'MM-DD-YYYY' },
    alwaysShowCalendars: false,
    singleDatePicker: true,
    startDate:(new Date().getMonth()+1)+"/"+new Date().getDate()+"/"+new Date().getFullYear(),

   //  maxDate:'07/25/2023',
 //    "maxSpan": {
 //     "days": 6
 //  },
  "autoApply": true,


  };
  f_message="Please wait.."
  filelist=[]
  stypec=""
  constructor(private router:Router,public service: WebapiService) { 

    this.getall();
    this.getallc();
    this.getall_o();
    this.getallc_o();
  }

  D_flag=false;
D_i=-1
sall="";
splist(){
  this.partnerfilter=[];
  this.f_filelist.forEach(element => {
    this.partnerfilter.push(element.type);
      
  });
  console.log(this.partnerfilter);

}
uplist(){
  this.partnerfilter=[];
}
plist(k){
  if(this.partnerfilter.indexOf(k)<0)
     this.partnerfilter.push(k)
  else
   {
     var j=this.partnerfilter.indexOf(k);
     this.partnerfilter.splice(j,1);
   }   
  console.log(this.partnerfilter);
}
download(type,i) {

  this.D_i=i
//   this.service.postData({}, 'download/'+type).then((result) => {
//     var temp: any = result;
//     this.resultr = temp
//     console.log(this.resultr);

// })

this.D_flag=true;
this.service.download(type).then((result) => {
  this.D_flag=false;
  this.D_i=-1

  

});
}

  selectedDate(value: any, datepicker?: any) {
    // this is the date  selected
    console.log(value);
    this.date=value.start.format('MM/DD/YYYY');
    console.log(this.date);

    // any object can be passed to the selected event and it will be passed back here
    // datepicker.start = value.start.startOf('week');
    // datepicker.end = value.end.endOf('week');
  }
  stype=''
  page=0;
  partnerlist=[
    'DBS',
    'EI',
    'DHL',
    'FLXT',
    'KWE',
    'EMO',
    'CEVA'
]
partnerlist_ociean=[
  'DHL In Transit',
  'DBS In Transit',
  'EMO In Transit',
  'EI In Transit'
]
date=""

filelistc=[];
  ngOnInit() {}
  
  unique_date=[];
  unique_date_ociean=[];
  selectedadate="";
  f_filelist_o=[];
  getselectedmonth(k){
    this.f_filelist=this.filelist.filter(function(item:any){return item.file_month===k})
 

    //return padb;
  }
  getselectedmonth_o(k){
    this.f_filelist_o=this.filelist_o.filter(function(item:any){return item.file_month===k})
 

    //return padb;
  }
  setpage(k){
    this.page=k;
  }
  f_filelist=[];
  getall()
  {
    this.f_flag=true;
    this.f_message="Please wait.."
    this.filelist=[]
    
         this.service.postData({type:'i'}, 'getallfiletype').then((result) => {
                  var temp: any = result;
                   console.log(temp)
                  
                  this.filelist =temp.data
                     //unique partner
                this.unique_date = this.filelist
                      .map((item) => item['file_month'])
                      .filter(
                          (value, index, current_value) => current_value.indexOf(value) === index
                      );
                  console.log("2");
                this.selectedadate=this.unique_date[0];
                this.getselectedmonth(this.selectedadate)
                this.f_flag=false;

                
            
            }).catch(e => {
              this.f_flag=true;
              this.f_message="Something goes wrong!"

            });
            
            
  }
  filelist_o=[]
  getall_o()
  {
    this.f_flag=true;
    this.f_message="Please wait.."
    this.filelist_o=[]
    
         this.service.postData({type:'r'}, 'getallfiletype').then((result) => {
                  var temp: any = result;
                   console.log(temp)
                  
                  this.filelist_o =temp.data
                     //unique partner
                this.unique_date_ociean= this.filelist_o
                      .map((item) => item['file_month'])
                      .filter(
                          (value, index, current_value) => current_value.indexOf(value) === index
                      );
                  console.log("2");
                this.selectedadate=this.unique_date[0];
                this.getselectedmonth_o(this.selectedadate)
                this.f_flag=false;

                
            
            }).catch(e => {
              this.f_flag=true;
              this.f_message="Something goes wrong!"

            });
            
            
  }
  values ={
    partnerlist:[],
    ndays:45
  }
  status="";
  setMessage9() {
      

    //this.values.partnerlist=this.partnerlist;
    // this.values.partnerlist=[];
    // this.f_filelist.forEach(element => {
    //     this.values.partnerlist.push(element.type.split(" ")[0].trim())
    // });
    this.values.partnerlist=[];
    this.partnerfilter.forEach(element => {
        this.values.partnerlist.push(element.split(" ")[0].trim())
    });
    // this.values.partnerlist=this.partnerfilter;
    // this.status="";

    this.values.ndays=parseInt(this.values.ndays+"")
    this.status="Please wait..";

    console.log(this.values)
    this.service.postData(this.values, 'demand_sensing_training').then((result) => {
      var temp: any = result;
      this.resultr = temp
      console.log(temp);
      //this.getall();
  
      this.status="Completed"
  
    }).catch(e => {

      this.status="Somthing goes wrong!"
      
    });
  
  }

  setMessage10() {
      

    //this.values.partnerlist=this.partnerlist;
    // this.values.partnerlist=[];
    // this.f_filelist.forEach(element => {
    //     this.values.partnerlist.push(element.type.split(" ")[0].trim())
    // });
    this.values.partnerlist=[];
    this.partnerfilter.forEach(element => {
        this.values.partnerlist.push(element.split(" ")[0].trim())
    });
    // this.values.partnerlist=this.partnerfilter;
    // this.status="";

    this.values.ndays=parseInt(this.values.ndays+"")
    this.status="Please wait..";

    console.log(this.values)
    this.service.postData(this.values, 'ocean_weekly_training').then((result) => {
      var temp: any = result;
      this.resultr = temp
      console.log(temp);
      //this.getall();
  
      this.status="Completed"
  
    }).catch(e => {

      this.status="Somthing goes wrong!"
      
    });
  
  }

  getallc()
  {
    this.f_flag=true;
    this.f_message="Please wait.."
    this.filelist=[]
         this.service.postData({type:'ic'}, 'getallfiletype').then((result) => {
                  var temp: any = result;
                   console.log(temp)
                  
                  this.filelistc =temp.data
                  console.log("2");
                  this.f_flag=false;

                
            
            }).catch(e => {
              this.f_flag=true;
              this.f_message="Something goes wrong!"

            });
            
            
  }
  filelistc_o=[];
  getallc_o()
  {
    this.f_flag=true;
    this.f_message="Please wait.."
    this.filelist=[]
         this.service.postData({type:'rc'}, 'getallfiletype').then((result) => {
                  var temp: any = result;
                   console.log(temp)
                  
                  this.filelistc_o =temp.data
                  console.log("2");
                  this.f_flag=false;

                
            
            }).catch(e => {
              this.f_flag=true;
              this.f_message="Something goes wrong!"

            });
            
            
  }
  uploadstatus=false;
  resultr:any;
  uploadstatus_message=""
  upload(e,type,tp,date)
   {
     
       this.uploadstatus=true;
       this.uploadstatus_message="Please wait.."
 
       var form_data = new FormData();  
             //console.log(model);
             console.log(e.target.files)
             for(var i=0;i<e.target.files.length;i++)
                 form_data.append('file', e.target.files.item(i)); 
             
                 form_data.append('type', type); 
                 form_data.append('uby', localStorage.getItem("email"));
                 form_data.append('tp', tp); 
                 form_data.append('date', date); 
 

                 this.service.postData1(form_data, 'upload_type').then((result) => {
                   var temp: any = result;
                   this.resultr = temp
                   console.log("2");
                   this.uploadstatus=true;
                   this.uploadstatus_message="File uploaded successfully"
 
                   this.getall();
                   this.getall_o();

                   
             
             }).catch(e => {
               this.uploadstatus=true;
               this.uploadstatus_message="Somthing goes wrong!";
                  
             });
     
            
   }
   gototraining(){
    
    this.router.navigateByUrl('/demandsensingnew');


   }
  GOtoNExtPage_2() {
    this.router.navigateByUrl('/operational-concern');

  }
  GOtoNExtPage_1() {
    
    this.router.navigateByUrl('/dashboard');
  }
  GOtoNExtPage_P()
  {
    this.router.navigateByUrl('/operational-concern1');
  }
  GOtomessage() {
      
    this.router.navigateByUrl('/messages');
  }
  
  go_config()
  {
    this.router.navigateByUrl('/mle-training');
  
  }

}
