import { Component, OnInit, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import { GoogleChartInterface } from 'ng2-google-charts/google-charts-interfaces';

@Component({
  selector: 'app-model-linechart',
  templateUrl: './model-linechart.component.html',
  styleUrls: ['./model-linechart.component.scss'],
})
export class ModelLinechartComponent implements OnInit {


  modelData: any;




  bars: any;
  colorArray: any;
  indexData: any;
  constructor() { }

  ionViewDidEnter() {

  }


  public stackedColumnChart: GoogleChartInterface = {
    chartType: 'BarChart',
    dataTable: [
      ['Cost', 'Total Assorial', 'Total Cost'],
      ['2010', 10, 90],
      ['2020', 40, 60],
      ['2030', 44, 56]
    ],
    options: {
      colors: ['#4747d1', '#adadeb'],
      legend: { position: 'top', maxLines: 3 },
      bar: { groupWidth: '40%' },
      isStacked: 'percent'
    }
  };

  ngOnInit() {
    var abc: any = `${this.modelData}`;
    console.log(abc)
    var list: any = this.modelData.data;
    var temp: any = [];
    debugger
    temp.push(['Cost', 'Total Assorial', 'Total Cost'])
    list.forEach((_a, index) => {
      if (index < this.indexData) {
        var a: any = +_a.Year + '-' + _a.Month
        temp.push([a, _a.Total_assesorial, 100 - _a.Total_assesorial])
      }
    });
    this.stackedColumnChart = {
      chartType: 'BarChart',
      dataTable: temp,
      options: {
        colors: ['#4747d1', '#adadeb'],
        legend: { position: 'top', maxLines: this.indexData },
        bar: { groupWidth: '40%' },
        isStacked: 'percent'
      }
    };
    console.log(this.stackedColumnChart)
  }

}