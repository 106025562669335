import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-infowindow',
  templateUrl: './infowindow.component.html',
  styleUrls: ['./infowindow.component.scss'],
})
export class InfowindowComponent implements OnInit {

  constructor(public router:Router) { }

  ngOnInit() {}
  gototraining(){
    this.router.navigateByUrl("/demandsensingnew")

  }
  go_config(){

    this.router.navigateByUrl("/demandsensingnew")
  }
}
