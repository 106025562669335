import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../webapi.service';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-airdashboard',
  templateUrl: './airdashboard.component.html',
  styleUrls: ['./airdashboard.component.scss'],
})
export class AirdashboardComponent implements OnInit {

  datelist:any=[]
  flag=false;
  cobbycarrier_pichart:any;
  cobbyservicetype_pichart:any;
  cobbyflighttype_pichart:any;
  cobbypartner_pichart:any;
  data_obj:any;
  selectedtab=0;
  selecteddaterange=0;
  lc:any;
  selectedorigin="HKG";
  selectepartner="DBS";

  loader=false;
  dropdownSettings:IDropdownSettings;
  dropdownSettings1:IDropdownSettings;

  opt={minimumFractionDigits:0, maximumFractionDigits: 0}
  opt1={minimumFractionDigits:2, maximumFractionDigits: 2}
  selectedItems:any=['DBS','DHL']
  selectedItemsOrigin:any=['HKG','MST']
  constructor(public service:WebapiService,public router:Router,public modalController:ModalController) { 

    //console.log(this.datelist);
    this.get_airdashboard();
    //this.draw_summary_pichart();

  }
  get_distinct_datelist(){

    this.datelist = this.data_obj.df_origin.filter(function (a) {
      var key = a['COB_Month'] + '|' + a['COB_Year'];
      if (!this[key]) {
          this[key] = true;
          return true;
      }
  }, Object.create(null));

  // this.datelist=[{COB_Month:1,COB_Year:2024}]
  //console.log(this.datelist)
  }
  selectedlane="HKG-SIN"
  lanelist=[];
  get_distinct_lane(){

    this.lanelist = this.data_obj.df_hawb_json.filter(function (a) {
      var key = a['Lane'] + '|' + a['Lane'];
      if (!this[key]) {
          this[key] = true;
          return true;
      }
  }, Object.create(null));

  //console.log(this.datelist)
  }
  get_airdashboard(){
    this.service.postData({}, 'get_air_dashboard_data').then((result) => {
      //console.log(result)
      var res:any=result;
      this.data_obj=res;

      this.data_obj.all_p=JSON.parse(res.all_p)
      this.data_obj.all_po=JSON.parse(res.all_po)
      this.data_obj.by_p=JSON.parse(res.by_p)
      this.data_obj.by_po=JSON.parse(res.by_po)
      
      this.data_obj.df_origin=JSON.parse(res.df_origin)
      this.data_obj.df_partner=JSON.parse(res.df_partner)
      this.data_obj.df_carriersummary=JSON.parse(res.df_carriersummary)
      this.data_obj.df_wieght_json=JSON.parse(res.df_wieght_json)
      this.data_obj.df_hawb_json=JSON.parse(res.df_hawb_json)

      this.get_distinct_datelist()
      this.getdistinctpartner();
       this.getdistinctorigin();
     this.draw_summary_pichart();
     this.getsummarybyservicetype();
     this.getsummarybycarrrier();
     this.getsummarybyaircraft();
     this.get_distinct_lane();


      this.flag=true;
    });
  }
  distinctpartner:any=[];
  distinctorign:any=[];

  ngOnInit() {

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'PARTNER',
      textField: 'PARTNER',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };
    this.dropdownSettings1 = {
      singleSelection: false,
      idField: 'ORIGIN_BRANCH',
      textField: 'ORIGIN_BRANCH',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };
  }

  changemonth(k:any){
  this.selecteddaterange=k;
  this.draw_summary_pichart();
  this.getsummarybyservicetype();
  this.getsummarybycarrrier();
  this.getsummarybyaircraft();
  
  }

  
  total_sum=0;
  summarybyServicetype:any=[]
  summarybycarrier:any=[]
  getsummarybyservicetype(){
    var month=this.datelist[this.selecteddaterange].COB_Month
    var year=this.datelist[this.selecteddaterange].COB_Year
    var data= this.data_obj.df_wieght_json.filter(function(item:any){
      // //console.log(item)
      return item.COB_Month==month && item.COB_Year==year })

    var result = [];
    data.reduce(function(res, value) {
      if (!res[value['Service Type']]) {
        res[value['Service Type']] = { Servicetype: value['Service Type'], COB_WEIGHT_CHARGEABLE: 0 };
        result.push(res[value['Service Type']])
      }
      res[value['Service Type']].COB_WEIGHT_CHARGEABLE += value.COB_WEIGHT_CHARGEABLE;
      return res;
    }, {});
    //console.log(result);
    result.sort(this.sortByProperty("COB_WEIGHT_CHARGEABLE"));

    this.summarybyServicetype=result;
  }
  summarycarrier_chart:any;
  getsummarybycarrrier(){
    var month=this.datelist[this.selecteddaterange].COB_Month
    var year=this.datelist[this.selecteddaterange].COB_Year
    var data= this.data_obj.df_wieght_json.filter(function(item:any){
      // //console.log(item)
      return item.COB_Month==month && item.COB_Year==year })

    var result = [];
    data.reduce(function(res, value) {
      if (!res[value['Carrier Name']]) {
        res[value['Carrier Name']] = { Carrier: value['Carrier Name'], COB_WEIGHT_CHARGEABLE: 0 };
        result.push(res[value['Carrier Name']])
      }
      res[value['Carrier Name']].COB_WEIGHT_CHARGEABLE += value.COB_WEIGHT_CHARGEABLE;

      
      
      return res;
    }, {});
    //console.log(result);
    result.sort(this.sortByProperty("COB_WEIGHT_CHARGEABLE"));

    var barchart = {
      chartType: 'ColumnChart',
      dataTable: [],
       options: {
        options: {
  
          title: 'Monthly Spend Variance',
          
        },
        annotations: {
          textStyle: {
          color:'black'
          }
        },
        legend: { position: 'top', alignment: 'center' },
        isStacked:true,

        // bar: { groupWidth: "50%" },
        'chartArea': {'width': '60%', 'height': '60%'},

        vAxis: {  gridlines: {
          color: 'transparent'
        },
      },
      
      hAxis: {  gridlines: {
        color: 'transparent'
       }
      },
  
    
        // isStacked:"true",
        
        // vAxes: {
        //   // Adds titles to each axis.
        //   0: {title: 'Chargable Weeight'},
        // }, 
        colors:  ['#D1C4E9','#B3E5FC','#D7CCC8','#F48FB1']
        ,
      height:180,
      }
    }

    var temp=[];
    temp.push(['Carrier Name','COB'])
    for(var i=0;i<10;i++){
      temp.push([result[i]['Carrier'],result[i]['COB_WEIGHT_CHARGEABLE']])
    }

    barchart.dataTable=temp;
    this.summarycarrier_chart=JSON.parse(JSON.stringify(barchart));

    this.summarybycarrier=result;
  }
  summarybyaircraft:any=[]
  summarybyaircraft_chart:any;

  getsummarybyaircraft(){
    var month=this.datelist[this.selecteddaterange].COB_Month
    var year=this.datelist[this.selecteddaterange].COB_Year
    var data= this.data_obj.df_hawb_json.filter(function(item:any){
      // //console.log(item)
      return item.COB_Month==month && item.COB_Year==year })

    var result = [];
    data.reduce(function(res, value) {
      if (!res[value['General Aircraft Name']]) {
        res[value['General Aircraft Name']] = { aircraft: value['General Aircraft Name'], COB_WEIGHT_CHARGEABLE: 0 };
        result.push(res[value['General Aircraft Name']])
      }
      res[value['General Aircraft Name']].COB_WEIGHT_CHARGEABLE += value.COB_WEIGHT_CHARGEABLE;
      return res;
    }, {});
    //console.log(result);
    result.sort(this.sortByProperty("COB_WEIGHT_CHARGEABLE"));
    var barchart = {
      chartType: 'ColumnChart',
      dataTable: [],
       options: {
        options: {
  
          title: 'Monthly Spend Variance',
          
        },
        annotations: {
          textStyle: {
          color:'black'
          }
        },
        legend: { position: 'top', alignment: 'center' },
        isStacked:true,

        // bar: { groupWidth: "50%" },
        'chartArea': {'width': '60%', 'height': '60%'},

        vAxis: {  gridlines: {
          color: 'transparent'
        },
      },
      
      hAxis: {  gridlines: {
        color: 'transparent'
       }
      },
  
    
        // isStacked:"true",
        
        // vAxes: {
        //   // Adds titles to each axis.
        //   0: {title: 'Chargable Weeight'},
        // }, 
        colors:  ['#D1C4E9','#B3E5FC','#D7CCC8','#F48FB1']
        ,
      height:180,
      }
    }

    var temp=[];
    temp.push(['General Aircraft Name','COB'])
    for(var i=0;i<10;i++){
      temp.push([result[i].aircraft,result[i]['COB_WEIGHT_CHARGEABLE']])
    }

    barchart.dataTable=temp;
    this.summarybyaircraft_chart=JSON.parse(JSON.stringify(barchart));

    this.summarybyaircraft=result;
  }
  getdistinctpartner(){
    this.distinctpartner = this.data_obj.by_p
    .map((item) => item.PARTNER)
    .filter(
        (value, index, current_value) => current_value.indexOf(value) === index
    );
  }

  getdistinctorigin(){
    this.distinctorign = this.data_obj.df_origin
    .map((item) => item.ORIGIN_BRANCH)
    .filter(
        (value, index, current_value) => current_value.indexOf(value) === index
    );
    //console.log(this.distinctorign)
  }


  getfilertbypartner(partner:any){
    var month=this.datelist[this.selecteddaterange].COB_Month
    var year=this.datelist[this.selecteddaterange].COB_Year
    ////console.log(partner)
    var data= this.data_obj.by_p.filter(function(item:any){
      // //console.log(item)
      return item.PARTNER===partner && item.COB_Month==month && item.COB_Year==year })

    //console.log(data);

    return data;
  }
  getfilertbyorigin(orign:any){
    ////console.log(partner)
    var month=this.datelist[this.selecteddaterange].COB_Month
    var year=this.datelist[this.selecteddaterange].COB_Year
    var data= this.data_obj.df_origin.filter(function(item:any){
      // //console.log(item)
      return item.ORIGIN_BRANCH===orign && item.COB_Month==month && item.COB_Year==year})

  //  //console.log(data);

    return data;
  }
  getfilertbypartner_array(partner:any,array:any){
    var month=this.datelist[this.selecteddaterange].COB_Month
    var year=this.datelist[this.selecteddaterange].COB_Year
    ////console.log(partner)
    var data= array.filter(function(item:any){
      // //console.log(item)
      return item.PARTNER===partner && item.COB_Month==month && item.COB_Year==year })

    //console.log(data);

    return data;
  }
  getfilertbyorigin_array(orign:any,array:any){
    ////console.log(partner)
    var month=this.datelist[this.selecteddaterange].COB_Month
    var year=this.datelist[this.selecteddaterange].COB_Year
    var data= array.filter(function(item:any){
      // //console.log(item)
      return item.ORIGIN_BRANCH===orign && item.COB_Month==month && item.COB_Year==year})

  //  //console.log(data);

    return data;
  }
  getfilertbyoriginpartner_array(orign:any,partner:any,array:any){
    ////console.log(partner)
    var month=this.datelist[this.selecteddaterange].COB_Month
    var year=this.datelist[this.selecteddaterange].COB_Year
    var data= array.filter(function(item:any){
      // //console.log(item)
      return item.PARTNER===partner && item.ORIGIN_BRANCH===orign && item.COB_Month==month && item.COB_Year==year})

  //  //console.log(data);

    return data;
  }
  // bypartner

  summarybyServicetype_partner:any=[]
  summarybycarrier_partner:any=[]

  getsummarybyservicetype_partner(partner){
    // var month=this.datelist[this.selecteddaterange].COB_Month
    // var year=this.datelist[this.selecteddaterange].COB_Year
    // var data= this.data_obj.df_wieght_json.filter(function(item:any){
    //   // //console.log(item)
    //   return item.COB_Month==month && item.COB_Year==year })

    var result = [];
    this.getfilertbypartner_array(partner,this.data_obj.df_wieght_json).reduce(function(res, value) {
      if (!res[value['Service Type']]) {
        res[value['Service Type']] = { Servicetype: value['Service Type'], COB_WEIGHT_CHARGEABLE: 0 };
        result.push(res[value['Service Type']])
      }
      res[value['Service Type']].COB_WEIGHT_CHARGEABLE += value.COB_WEIGHT_CHARGEABLE;
      return res;
    }, {});
    //console.log(result);
    result.sort(this.sortByProperty("COB_WEIGHT_CHARGEABLE"));

  
    return result;
  }
  getsummarybycarrrier_partner(partner){
    var month=this.datelist[this.selecteddaterange].COB_Month
    var year=this.datelist[this.selecteddaterange].COB_Year
    var data= this.data_obj.df_wieght_json.filter(function(item:any){
      // //console.log(item)
      return item.COB_Month==month && item.COB_Year==year })

    var result = [];
    this.getfilertbypartner_array(partner,this.data_obj.df_wieght_json).reduce(function(res, value) {
      if (!res[value['Carrier Name']]) {
        res[value['Carrier Name']] = { Carrier: value['Carrier Name'], COB_WEIGHT_CHARGEABLE: 0 };
        result.push(res[value['Carrier Name']])
      }
      res[value['Carrier Name']].COB_WEIGHT_CHARGEABLE += value.COB_WEIGHT_CHARGEABLE;
      return res;
    }, {});
    //console.log(result);
    result.sort(this.sortByProperty("COB_WEIGHT_CHARGEABLE"));

    var barchart = {
      chartType: 'ColumnChart',
      dataTable: [],
       options: {
        options: {
  
          title: 'Monthly Spend Variance',
          
        },
        annotations: {
          textStyle: {
          color:'black'
          }
        },
        legend: { position: 'top', alignment: 'center' },
        isStacked:true,

        // bar: { groupWidth: "50%" },
        'chartArea': {'width': '60%', 'height': '60%'},

        vAxis: {  gridlines: {
          color: 'transparent'
        },
      },
      
      hAxis: {  gridlines: {
        color: 'transparent'
       }
      },
  
    
        // isStacked:"true",
        
        // vAxes: {
        //   // Adds titles to each axis.
        //   0: {title: 'Chargable Weeight'},
        // }, 
        colors:  ['#D1C4E9','#B3E5FC','#D7CCC8','#F48FB1']
        ,
      height:180,
      }
    }

    var temp=[];
    temp.push(['Carrier Name','COB'])
    for(var i=0;i<result.slice(0,10).length;i++){
      temp.push([result[i]['Carrier'],result[i]['COB_WEIGHT_CHARGEABLE']])
    }

    barchart.dataTable=temp;
    // console.log(barchart)
    return {result:result,chart:barchart}
    // return result;
  }
  summarybyaircraft_partner:any=[]

  getsummarybyaircraft_partner(partner){
    var month=this.datelist[this.selecteddaterange].COB_Month
    var year=this.datelist[this.selecteddaterange].COB_Year
    var data= this.data_obj.df_hawb_json.filter(function(item:any){
      // //console.log(item)
      return item.COB_Month==month && item.COB_Year==year })

    var result = [];
    this.getfilertbypartner_array(partner,this.data_obj.df_hawb_json).reduce(function(res, value) {
      if (!res[value['General Aircraft Name']]) {
        res[value['General Aircraft Name']] = { aircraft: value['General Aircraft Name'], COB_WEIGHT_CHARGEABLE: 0 };
        result.push(res[value['General Aircraft Name']])
      }
      res[value['General Aircraft Name']].COB_WEIGHT_CHARGEABLE += value.COB_WEIGHT_CHARGEABLE;
      return res;
    }, {});
    //console.log(result);
    result.sort(this.sortByProperty("COB_WEIGHT_CHARGEABLE"));

    return result;
  }


  getcarriersummary_partner(partner){
    
    var month=this.datelist[this.selecteddaterange].COB_Month
    var year=this.datelist[this.selecteddaterange].COB_Year
    var data= this.data_obj.by_p.filter(function(item:any){
      // //console.log(item)
      return item.PARTNER==partner && item.COB_Month==month && item.COB_Year==year })

    var result = [];
    // this.getfilertbypartner_array(partner,this.data_obj.df_wieght_json).reduce(function(res, value) {
    //   if (!res[value['Carrier Name']]) {
    //     res[value['Carrier Name']] = { Carrier: value['Carrier Name'], COB_WEIGHT_CHARGEABLE: 0 };
    //     result.push(res[value['Carrier Name']])
    //   }
    //   res[value['Carrier Name']].COB_WEIGHT_CHARGEABLE += value.COB_WEIGHT_CHARGEABLE;
    //   return res;
    // }, {});
    // //console.log(result);
    result.sort(this.sortByProperty("COB_WEIGHT_CHARGEABLE"));

    return data;
  }

  onItemSelect(e){

  }
  onSelectAll(e){

  }
  getsummarybyservicetype_origin(origin){
    // var month=this.datelist[this.selecteddaterange].COB_Month
    // var year=this.datelist[this.selecteddaterange].COB_Year
    // var data= this.data_obj.df_wieght_json.filter(function(item:any){
    //   // //console.log(item)
    //   return item.COB_Month==month && item.COB_Year==year })

    var result = [];
    this.getfilertbyorigin_array(origin,this.data_obj.df_wieght_json).reduce(function(res, value) {
      if (!res[value['Service Type']]) {
        res[value['Service Type']] = { Servicetype: value['Service Type'], COB_WEIGHT_CHARGEABLE: 0 };
        result.push(res[value['Service Type']])
      }
      res[value['Service Type']].COB_WEIGHT_CHARGEABLE += value.COB_WEIGHT_CHARGEABLE;
      return res;
    }, {});
    //console.log(result);
    result.sort(this.sortByProperty("COB_WEIGHT_CHARGEABLE"));

    return result;
  }
  getsummarybycarrrier_origin(origin){
    var month=this.datelist[this.selecteddaterange].COB_Month
    var year=this.datelist[this.selecteddaterange].COB_Year
    var data= this.data_obj.df_wieght_json.filter(function(item:any){
      // //console.log(item)
      return item.COB_Month==month && item.COB_Year==year })

    var result = [];
    this.getfilertbyorigin_array(origin,this.data_obj.df_wieght_json).reduce(function(res, value) {
      if (!res[value['Carrier Name']]) {
        res[value['Carrier Name']] = { Carrier: value['Carrier Name'], COB_WEIGHT_CHARGEABLE: 0 };
        result.push(res[value['Carrier Name']])
      }
      res[value['Carrier Name']].COB_WEIGHT_CHARGEABLE += value.COB_WEIGHT_CHARGEABLE;
      return res;
    }, {});
    //console.log(result);
    result.sort(this.sortByProperty("COB_WEIGHT_CHARGEABLE"));

    return result;
  }
  summarybyaircraft_origin:any=[]

  getsummarybyaircraft_origin(origin){
    var month=this.datelist[this.selecteddaterange].COB_Month
    var year=this.datelist[this.selecteddaterange].COB_Year
    var data= this.data_obj.df_hawb_json.filter(function(item:any){
      // //console.log(item)
      return item.COB_Month==month && item.COB_Year==year })

    var result = [];
    this.getfilertbyorigin_array(origin,this.data_obj.df_hawb_json).reduce(function(res, value) {
      if (!res[value['General Aircraft Name']]) {
        res[value['General Aircraft Name']] = { aircraft: value['General Aircraft Name'], COB_WEIGHT_CHARGEABLE: 0 };
        result.push(res[value['General Aircraft Name']])
      }
      res[value['General Aircraft Name']].COB_WEIGHT_CHARGEABLE += value.COB_WEIGHT_CHARGEABLE;
      return res;
    }, {});
    //console.log(result);
    result.sort(this.sortByProperty("COB_WEIGHT_CHARGEABLE"));

    return result;
  }


  getcarriersummary_origin(origin){
    
    var month=this.datelist[this.selecteddaterange].COB_Month
    var year=this.datelist[this.selecteddaterange].COB_Year
    var data= this.data_obj.by_po.filter(function(item:any){
      // //console.log(item)
      return item.ORIGIN_BRANCH==origin && item.COB_Month==month && item.COB_Year==year })

    var result = [];
    // this.getfilertbyorigin_array(origin,this.data_obj.df_wieght_json).reduce(function(res, value) {
    //   if (!res[value['Carrier Name']]) {
    //     res[value['Carrier Name']] = { Carrier: value['Carrier Name'], COB_WEIGHT_CHARGEABLE: 0 };
    //     result.push(res[value['Carrier Name']])
    //   }
    //   res[value['Carrier Name']].COB_WEIGHT_CHARGEABLE += value.COB_WEIGHT_CHARGEABLE;
    //   return res;
    // }, {});
    // //console.log(result);
    result.sort(this.sortByProperty("COB_WEIGHT_CHARGEABLE"));

    return data;
  }

  getcarrierhabsummary_origin(lane,partner){
    
    var month=this.datelist[this.selecteddaterange].COB_Month
    var year=this.datelist[this.selecteddaterange].COB_Year
    var data= this.data_obj.df_hawb_json.filter(function(item:any){
      // //console.log(item)
      return item.Lane==lane && item.PARTNER==partner && item.COB_Month==month && item.COB_Year==year })

    // var result = [];
    // this.getfilertbyorigin_array(origin,this.data_obj.df_wieght_json).reduce(function(res, value) {
    //   if (!res[value['Carrier Name']]) {
    //     res[value['Carrier Name']] = { Carrier: value['Carrier Name'], COB_WEIGHT_CHARGEABLE: 0 };
    //     result.push(res[value['Carrier Name']])
    //   }
    //   res[value['Carrier Name']].COB_WEIGHT_CHARGEABLE += value.COB_WEIGHT_CHARGEABLE;
    //   return res;
    // }, {});
    console.log(data);
    // result.sort(this.sortByProperty("COB_WEIGHT_CHARGEABLE"));


    return data;
  }

  getsummarybyservicetype_origin_partner(origin,partner){
    // var month=this.datelist[this.selecteddaterange].COB_Month
    // var year=this.datelist[this.selecteddaterange].COB_Year
    // var data= this.data_obj.df_wieght_json.filter(function(item:any){
    //   // //console.log(item)
    //   return item.COB_Month==month && item.COB_Year==year })

    var result = [];
    this.getfilertbyoriginpartner_array(origin,partner,this.data_obj.df_wieght_json).reduce(function(res, value) {
      if (!res[value['Service Type']]) {
        res[value['Service Type']] = { Servicetype: value['Service Type'], COB_WEIGHT_CHARGEABLE: 0 };
        result.push(res[value['Service Type']])
      }
      res[value['Service Type']].COB_WEIGHT_CHARGEABLE += value.COB_WEIGHT_CHARGEABLE;
      return res;
    }, {});
    //console.log(result);
    result.sort(this.sortByProperty("COB_WEIGHT_CHARGEABLE"));

    return result;
  }
  getsummarybycarrrier_origin_partner(origin,partner){
    var month=this.datelist[this.selecteddaterange].COB_Month
    var year=this.datelist[this.selecteddaterange].COB_Year
    var data= this.data_obj.df_wieght_json.filter(function(item:any){
      // //console.log(item)
      return item.COB_Month==month && item.COB_Year==year })

    var result = [];
    this.getfilertbyoriginpartner_array(origin,partner,this.data_obj.df_wieght_json).reduce(function(res, value) {
      if (!res[value['Carrier Name']]) {
        res[value['Carrier Name']] = { Carrier: value['Carrier Name'], COB_WEIGHT_CHARGEABLE: 0 };
        result.push(res[value['Carrier Name']])
      }
      res[value['Carrier Name']].COB_WEIGHT_CHARGEABLE += value.COB_WEIGHT_CHARGEABLE;
      return res;
    }, {});
    console.log(result);
    result.sort(this.sortByProperty("COB_WEIGHT_CHARGEABLE"));

    return result;
  }

  getsummarybyaircraft_origin_partner(origin,partner){
    var month=this.datelist[this.selecteddaterange].COB_Month
    var year=this.datelist[this.selecteddaterange].COB_Year
    var data= this.data_obj.df_hawb_json.filter(function(item:any){
      // //console.log(item)
      return item.COB_Month==month && item.COB_Year==year })

    var result = [];
    this.getfilertbyoriginpartner_array(origin,partner,this.data_obj.df_hawb_json).reduce(function(res, value) {
      if (!res[value['General Aircraft Name']]) {
        res[value['General Aircraft Name']] = { aircraft: value['General Aircraft Name'], COB_WEIGHT_CHARGEABLE: 0 };
        result.push(res[value['General Aircraft Name']])
      }
      res[value['General Aircraft Name']].COB_WEIGHT_CHARGEABLE += value.COB_WEIGHT_CHARGEABLE;
      return res;
    }, {});
    //console.log(result);
    result.sort(this.sortByProperty("COB_WEIGHT_CHARGEABLE"));

    return result;
  }


  getcarriersummary_origin_partner(origin,partner){
    
    var month=this.datelist[this.selecteddaterange].COB_Month
    var year=this.datelist[this.selecteddaterange].COB_Year
    var data= this.data_obj.df_wieght_json.filter(function(item:any){
      console.log( item)
      return item.PARTNER==partner &&  item.ORIGIN_BRANCH==origin && item.COB_Month==month && item.COB_Year==year })

    var result = [];
    // this.getfilertbyorigin_array(origin,this.data_obj.df_wieght_json).reduce(function(res, value) {
    //   if (!res[value['Carrier Name']]) {
    //     res[value['Carrier Name']] = { Carrier: value['Carrier Name'], COB_WEIGHT_CHARGEABLE: 0 };
    //     result.push(res[value['Carrier Name']])
    //   }
    //   res[value['Carrier Name']].COB_WEIGHT_CHARGEABLE += value.COB_WEIGHT_CHARGEABLE;
    //   return res;
    // }, {});
    console.log(data);
    result.sort(this.sortByProperty("COB_WEIGHT_CHARGEABLE"));

    return data;
  }


  carriersummary:any=[]
  draw_summary_pichart(){

    var barchart = {
      chartType: 'ColumnChart',
      dataTable: temp,
       options: {
        options: {
  
          title: 'Monthly Spend Variance',
          
        },
        annotations: {
          textStyle: {
          color:'black'
          }
        },
        legend: { position: 'top', alignment: 'center' },
        isStacked:true,

        // bar: { groupWidth: "50%" },
        'chartArea': {'width': '70%', 'height': '70%'},

        vAxis: {  gridlines: {
          color: 'transparent'
        },
      },
      
      hAxis: {  gridlines: {
        color: 'transparent'
       }
      },
  
    
        // isStacked:"true",
        
        // vAxes: {
        //   // Adds titles to each axis.
        //   0: {title: 'Chargable Weeight'},
        // }, 
        colors:  ['#D1C4E9','#B3E5FC','#D7CCC8','#F48FB1']
        ,
      height:180,
      }
    }

    var month=this.datelist[this.selecteddaterange].COB_Month
    var year=this.datelist[this.selecteddaterange].COB_Year
    var data= this.data_obj.df_carriersummary.filter(function(item:any){
      // //console.log(item)
      return item.COB_Month==month && item.COB_Year==year })

      this.carriersummary=data;
     var temp=[];
     temp.push(['Carrier','COB'])
     for(var i=5;i<data.length;i++)
     {
       this.total_sum=this.total_sum+data[i]['COB_WEIGHT_CHARGEABLE'];
     }
     for(var i=0;i<10;i++){
       temp.push([data[i]['Carrier Name'],data[i]['COB_WEIGHT_CHARGEABLE']])
     }
    // temp.push(['Other',this.total_sum])
    //  temp.push(['China Airlines',172109])
    //  temp.push(['Cargolux Airlines International',48125])
    //  temp.push(['Air China',17947])
    //  temp.push(['All Nippon Airways',79876])


    //console.log(temp)
    barchart.dataTable=temp;

     this.cobbycarrier_pichart=JSON.parse(JSON.stringify(barchart));

     temp=[];
     temp.push(['Service Type','COB'])
     temp.push(['Passenger Flight',748935])
     temp.push(['Cargo',1712217])
    


     barchart.dataTable=temp;

     this.cobbyservicetype_pichart=JSON.parse(JSON.stringify(barchart));


     temp=[];
     temp.push(['Flight Type','COB'])
     temp.push(['Airbus A350',12821178])
     temp.push(['Airbus A330 Freighter',232718677])
     temp.push(['Boeing 777 Passenger',12277567])
     
     
     

     barchart.dataTable=temp;

     this.cobbyflighttype_pichart=JSON.parse(JSON.stringify(barchart));
     

     
     temp=[];
     temp.push(['Partner','COB'])
     temp.push(['DBS',5621178])
     temp.push(['EI',232718677])
     temp.push(['DHL',132277567])
     temp.push(['FLXT',145786967])
     
     
     

     barchart.dataTable=temp;

     this.cobbypartner_pichart=JSON.parse(JSON.stringify(barchart));

  }
  sortByProperty(property){  
    return function(b,a){  
       if(a[property] > b[property])  
          return 1;  
       else if(a[property] < b[property])  
          return -1;  
   
       return 0;  
    }  
 }
  GOtoNExtPage_2() {
    
    this.router.navigateByUrl('/operational-concern');
  }
  gototraining(){
    this.router.navigateByUrl('/dailytraining');

  }
  GOtoNExtPage_1() {
    
    this.router.navigateByUrl('/dashboard');
  }
  GOtoNExtPage_P()
  {
    this.router.navigateByUrl('/operationasl-concern1');
  }
  GOtomessage() {
      
    this.router.navigateByUrl('/messages');
  }
  
  go_config()
  {
    this.router.navigateByUrl('/userprofile');
  
  }
}
