import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../webapi.service';

@Component({
  selector: 'app-shipercobdata',
  templateUrl: './shipercobdata.component.html',
  styleUrls: ['./shipercobdata.component.scss'],
})
export class ShipercobdataComponent implements OnInit {

  loading=false;
  title="";
  shipper:any=[];
  lc:any;
  user:any
  opt={minimumFractionDigits:0, maximumFractionDigits: 0}
  opt1={minimumFractionDigits:2, maximumFractionDigits: 2}
  constructor(public service:WebapiService) {
   

  }
  sortByProperty(property){  
    return function(b,a){  
       if(a[property] > b[property])  
          return 1;  
       else if(a[property] < b[property])  
          return -1;  
   
       return 0;  
    } 
  }
  ngOnInit() {

    this.loading=true;
    this.title="Daily reported Tonnage for "+this.user.oring+"-"+this.user.destination+" by Partner "+this.user.partner+" on "+this.user.date;
    this.service.postData(this.user, 'get_demand_sensing_by_date').then((result) => {
      this.loading=false;
      
      var res:any=result;
      console.log(result);
      this.shipper=JSON.parse(res.data); 
      this.shipper.sort(this.sortByProperty('COB_WEIGHT_CHARGEABLE'))
      console.log(this.shipper);
         
    });
  }

}
