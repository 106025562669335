import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ModalController } from '@ionic/angular';
import { WebapiService } from '../webapi.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-hdradashboardconfig',
  templateUrl: './hdradashboardconfig.component.html',
  styleUrls: ['./hdradashboardconfig.component.scss'],
})
export class HDRAdashboardconfigComponent implements OnInit {
  dropdownSettings:IDropdownSettings;
  dropdownSettings1:IDropdownSettings;
  dropdownSettingst:IDropdownSettings;
  constructor(public service: WebapiService,private  modalController:ModalController) {
this.getall()
 

   }

  resultr:any;

  
  f_flag=false;
  ratemasterlist=[]
  filelist=[];
  filelistt=[];
  selectedItems=[];
  selectedItemst=[];

  selectedItemsr=[]
  getall()
  {
    this.f_flag=true;
    this.filelist=[]
         this.service.postData({type:'c'}, 'get_hrda_lane').then((result) => {
                  var temp: any = result;
                   console.log(temp)
                  
                  this.resultr = temp
                  this.filelist =JSON.parse(this.resultr.lane);
                  this.filelistt =JSON.parse(this.resultr.lt);
                  this.ratemasterlist =JSON.parse(this.resultr.reason);
                  console.log(this.filelist)
                  this.getallsaved()              
            
            // this.f_flag=false;

            // this.f_flags=false;
                }).catch(e => {
//              this.f_flag=true;
             
            });
            
            
  }
  f_flags=false;

  getallsaved()
  {
        this.f_flags=true;
         this.service.postData({'userID':localStorage.getItem('user')}, 'get_save_hrda_lane').then((result) => {
                  var temp: any = result;
                   console.log(temp)
                  var list=[];
                  this.resultr = temp
                  list=this.resultr.data
                  if(list.length>0)
                  {
                  list.forEach(element => {
                    console.log(element)
                    console.log(JSON.parse(element.sItem))
                    if(element.sType=='lane')
                       this.selectedItems=JSON.parse(element.sItem);
                      else if(element.sType=='lanet') 
                       this.selectedItemst=JSON.parse(element.sItem);
                       else 
                      this.selectedItemsr=JSON.parse(element.sItem);
                      
                  });

                  }
                  else{
                  }
                  this.f_flag=false;

        this.f_flags=false;

            }).catch(e => {
             
            });
            
            
  }
save(type,data)
{
  this.service.postData({'type':type,'lane':JSON.stringify(data),'userID':localStorage.getItem('user')}, 'submit_hrda_lane').then((result) => {
    var temp: any = result;
     console.log(temp)
    
     alert("Saved successfully.")
    // this.resultr = temp
    // this.filelist =JSON.parse(this.resultr.lane);
    // this.ratemasterlist =JSON.parse(this.resultr.reason);
    // this.f_flag=false;

}).catch(e => {
this.f_flag=true;

});

}
  apply()
  {
     var sl=[]
     var cat=[]    
     var lanet=[]     
 
    if (this.selectedItems.length>0)
         sl=this.selectedItems
        else
        sl=this.filelist

        if (this.selectedItemsr.length>0)
        cat=this.selectedItemsr
       else
       cat=this.ratemasterlist

       if (this.selectedItemst.length>0)
       lanet=this.selectedItemst
      else
      lanet=this.filelistt

        this.modalController.dismiss({'lane':sl,'cate':cat,'lanet':lanet}) ;

  }  
  close()
  {
    this.apply();

  //   var sl=[]
  //   var cat=[]     
  //  if (this.selectedItems.length>0)
  //       sl=this.selectedItems
  //      else
  //      sl=this.filelist

  //      if (this.selectedItemsr.length>0)
  //      cat=this.selectedItemsr
  //     else
  //     cat=this.ratemasterlist


  //   this.modalController.dismiss({'lane':sl,'cate':cat}) ;

  }

  ngOnInit() {

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Lane group',
      textField: 'Lane group',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };

    this.dropdownSettingst = {
      singleSelection: false,
      idField: 'Lane group total',
      textField: 'Lane group total',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };
    this.dropdownSettings1 = {
      singleSelection: false,
      idField: 'Increase reason',
      textField: 'Increase reason',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };
  }

}
