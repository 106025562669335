
import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../webapi.service';

@Component({
  selector: 'app-ocean3b81details',
  templateUrl: './ocean3b81details.component.html',
  styleUrls: ['./ocean3b81details.component.scss'],
})
export class Ocean3b81detailsComponent implements OnInit {

  weekdata;
  flag;
  selectedweek;
  loading=false;
  title="";
  title1=""
  lc:any;
  opt={minimumFractionDigits:0, maximumFractionDigits: 0}
  opt1={minimumFractionDigits:2, maximumFractionDigits: 2}
  constructor(public web:WebapiService) { 


  }

  page=1;
  tableflag=true;
  ngOnInit() {
    console.log(this.weekdata);

    this.title="Weekly data for lane "+this.weekdata[0].Origin+"-"+this.weekdata[0].Destination;
    if(this.flag=='d'){
      this.selectweek(this.selectedweek)
    }
  }

  user = {
    origin:'',
    week:'',
    desti:'',
    lane:'',
    oring:'',
    destination:'',
    type_f:''
  }
  df3b18:any=[];
  ocean:any=[];
  air:any=[];
  recepits:any=[];

  selectweek(w)
  {

    this.selectedweek=w;

    var utcdate=new Date(this.selectedweek.Week).toLocaleString('en-US', { timeZone: 'UTC' });
    this.user.week=utcdate.split(",")[0]

    console.log(new Date(this.selectedweek.Week));
    this.title1="Detailed view for week "+this.user.week +" for "+this.selectedweek.Origin+"-"+this.selectedweek.Destination;

    this.tableflag=false;
    this.user.desti=this.selectedweek.Destination
    this.user.destination=this.selectedweek.Destination
    this.user.origin=this.selectedweek.Origin
    this.user.oring=this.selectedweek.Origin

    this.user.lane=this.selectedweek.Origin+"-"+this.selectedweek.Destination;

    //=(new Date(this.selectedweek.Week).getMonth()+1)+"/"+(new Date(this.selectedweek.Week).getDate()+1)+'/'+(new Date(this.selectedweek.Week).getFullYear());
    this.loading=true;
    this.web.postData(this.user, 'get_shipper_level_data_for_week').then((result) => {
      console.log(result);          
      var temp:any=result;

      this.air =JSON.parse(temp.air_filter_json);
      this.df3b18 =JSON.parse(temp.df_3b18_filter_json);
      this.ocean =JSON.parse(temp.ocean_filter_json);
      this.recepits =JSON.parse(temp.receipt_filter_json);

      console.log(this.air);
      console.log(this.df3b18)
      console.log(this.ocean)
      console.log(this.recepits)
      this.loading=false;


    });
  }
  setpage(p){
    this.page=p;
  }

  getreceiptvar(k){
    return (((Math.abs((k['Observed Chargeable Weight']+k['OCEAN_WEIGHT_CHARGEABLE'])-k['total_weight_kg']))/(k['Observed Chargeable Weight']+k['OCEAN_WEIGHT_CHARGEABLE']))*100).toLocaleString(this.lc,this.opt1);
  }
  gettotalptvar(k){
    return (((Math.abs((k['Observed Chargeable Weight']+k['OCEAN_WEIGHT_CHARGEABLE'])-k['Receipt_Weight']))/(k['Observed Chargeable Weight']+k['OCEAN_WEIGHT_CHARGEABLE']))*100).toLocaleString(this.lc,this.opt1);
  }

  flagdownloaa=false;
  getitemdetails(type){
    this.user.type_f=type
    this.flagdownloaa=true;
    this.web.postData( this.user, 'get_shipper_level_data_for_week_by_item').then((result) => {
      console.log(result);  
      var res:any=result;
      var data=JSON.parse(res.df_filter);
      var k=0;
      data.forEach(element => {
       
        if(type!='3B18 Volume by Item w weight')
         data[k].PO_RECEIVED_DATE=new Date(element.PO_RECEIVED_DATE).toLocaleString('en-US', { timeZone: 'UTC' }).split(",")[0]
        else
         data[k].ship_date=new Date(element.ship_date).toLocaleString('en-US', { timeZone: 'UTC' }).split(",")[0]
         k=k+1;
        });
      this.saveAsCSV(data,this.user.week+"_"+this.user.origin+"-"+this.user.destination+"_"+type)  
      this.flagdownloaa=false;
    });

  }

  saveAsCSV(sampleJson,filename) {

    // let sampleJson: any = e == 'IM' ? this.imbalence.data : this.GetCoralitionList.data;
    let a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    let csvData = this.ConvertToCSV(sampleJson);
    let blob = new Blob([csvData], { type: 'text/csv' });
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename+'.csv';
    a.click();
  }

  ConvertToCSV(objArray) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = "";
    for (let index in objArray[0]) {
      //Now convert each value to string and comma-separated
      row += index + ',';
    }
    row = row.slice(0, -1);
    //append Label row with line break
    str += row + '\r\n';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line != '') line += ',';
        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  
  }

}
