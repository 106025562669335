import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { WebapiService } from '../webapi.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-sharingpage',
  templateUrl: './sharingpage.component.html',
  styleUrls: ['./sharingpage.component.scss'],
})
export class SharingpageComponent implements OnInit {
  resultr: any;
  data;
  viewrecords:any;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings:IDropdownSettings;
  constructor(public service: WebapiService, public modalController: ModalController) {
   this.user.userid=localStorage.getItem('user')
   this.user.from=localStorage.getItem('email');

  }
  m:any
  user = {
    summary:this.m,
    email:[],
    from:'',
    note:'',
    userid:''
  }
  close()

  {
    this.modalController.dismiss({
      'dismissed': true
    });
  }
  getdata: any;
  getuser() {
    this.user.from
    this.service.postData(this.user, 'get_user').then((result) => {
      var temp: any = result;
      this.resultr = temp
      console.log(this.resultr);
      if (this.resultr.result == 1) {

        this.dropdownList = this.resultr.record;
        console.log(this.dropdownList)

      } else {
        alert("Data Not Loaded")
      }



    })
  }
  addsummary() {

    this.user.email=this.selectedItems;
    if(this.user.email.length==0)
    {        
      alert("Please select at least on user.")

      return; 
    }
    this.service.postData(this.user, 'summary_notification').then((result) => {
      //debugger
      var temp: any = result;
      this.resultr = temp
      console.log(this.resultr);
      if (this.resultr.result == 1) {
        let navigationExtras: any = {
          queryParams: {
            special: JSON.stringify(this.user)
          }
        };
        alert("Messge shared successfuly")
       // this.getDelegates();
       this.close()

      } else if (this.resultr.result == 2) {
        alert('Some thing goes wrong');
      }

      else {
        let navigationExtras: any = {
          queryParams: {
            special: JSON.stringify(this.user)
          }
        };
      }

    })

  }
  ngOnInit() {
        this.viewrecords=this.data;
    console.log(this.viewrecords);
    this.user.summary=this.viewrecords
    this.getuser()
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'semail',
      textField: 'semail',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

}
